/* General Mermaid Styles (light mode) */
.mermaid svg {
    font-family: 'trebuchet ms', verdana, arial, sans-serif;
    font-size: 14px;
    fill: #333; /* Default text color */
}

.mermaid svg .node rect {
    stroke: #333; /* Default node border */
    fill: #eee;  /* Default node fill */
}

/* Styles for primary source nodes */
.primary-source rect {
    fill: #FFD700;
    stroke: #333;
    stroke-width: 3px;
}

/* Styles for secondary source nodes */
.secondary-source rect {
    fill: #ADD8E6;
    stroke: #333;
    stroke-width: 2px;
}

/* Style the legend box */
.legend-box rect {
    fill: none;
    stroke: #333;
    stroke-dasharray: 5 5;
}

/* Dark Mode Overrides */
[data-theme="dark"] .mermaid svg {
    fill: #d1d5db; /* Default text color in dark mode */

    /* Node styles */
    & .node rect,
    & .node circle,
    & .node ellipse,
    & .node polygon {
        stroke: #d1d5db;
        fill: #1f2937;
    }

    /* Text within nodes, clusters, and legend */
    & .nodeLabel,
    & g.classGroup text,
    & .legend-title,
    & .legend-text {
        fill: #f3f4f6;
    }

    /* Primary source styles */
    & .primary-source rect {
        fill: #FFD700;
        stroke: #d1d5db;
    }

    /* Secondary source styles */
    & .secondary-source rect {
        fill: #ADD8E6;
        stroke: #d1d5db;
    }

    /* Legend box style */
    & .legend-box rect {
        stroke: #d1d5db;
    }

    /* Edge (connector) and arrowhead styles */
    & .edgePath path,
    & .marker {
        stroke: #6b7280;
    }
    & .arrowheadPath {
        fill: #6b7280;
    }
    /* Edge label styles */
    & .edgeLabel rect {
        fill: #1f2937;
    }
    & .edgeLabel text {
        fill: #d1d5db;
    }
}